














































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FindTagUseCase, SaveTagUseCase } from '../../usecases'
import { Page, Tag } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { DataOptions } from 'vuetify'
import { Pageable } from '@/models/Pageable'
import axios, { CancelTokenSource } from 'axios'
import { ObserveVisibility } from 'vue-observe-visibility'

@Component({
	model: {
		prop: 'tag',
	},
	directives: {
		ObserveVisibility,
	},
})
export default class SeletorDeTag extends Vue {
	@Prop({ type: [Object, Array], default: null }) tag!: (string | Tag) | (string | Tag)[] | null
	@Prop({ type: Boolean }) podeCriar!: boolean
	@Prop({ type: Boolean }) podeEditar!: boolean
	@Prop({ type: Boolean }) returnObject!: boolean
	@Prop({ type: Boolean }) multiple!: boolean
	busca = ''
	itemEditando: null | ItemSelecao = null
	itensSelecao: ItemSelecao[] = []
	findTagUseCase = new FindTagUseCase()
	saveTagUseCase = new SaveTagUseCase()

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	pagina: Page<Tag> | null = null
	cancelToken: CancelTokenSource | null = null

	buscandoMaisItens = false
	buscandoNovosItens = true

	created() {
		this.buscarNovosItens()
	}

	tagEmItemAutocompletar(tag: Tag | string): ItemSelecao {
		return typeof tag === 'string'
			? {
				id: '',
				nome: tag,
				tipo: 'selecao',
			}
			: {
				...tag,
				tipo: 'selecao',
			}
	}

	get itens() {
		let itens: ItemAutocompletar[] = this.tag === null
			? []
			: (Array.isArray(this.tag)
				? this.tag.map(this.tagEmItemAutocompletar)
				: [this.tagEmItemAutocompletar(this.tag)])

		itens = [
			...itens,
			...this.podeCriar
				? [{
					tipo: 'header',
					header: 'Selecione uma opção ou crie uma',
				} as ItemHeader]
				: [],
			...this.itensSelecao,
		]
		if (this.pagina && !this.pagina.last && !this.buscandoNovosItens)
			itens.push({ tipo: 'buscar-mais' })

		const tags = this.tag
		if (!this.podeCriar || !this.busca || this.buscandoNovosItens) return itens
		if (!tags) return [{ tipo: 'novo' }, ...itens]
		if (Array.isArray(tags)) {
			return tags.some(
				tag => this.busca.toLowerCase() === this.obterNomeTag(tag).toLowerCase(),
			)
				? itens
				: [{ tipo: 'novo' }, ...itens]
		}
		return this.busca.toLowerCase() === this.obterNomeTag(tags).toLowerCase()
			? itens
			: [{ tipo: 'novo' }, ...itens]
	}

	obterNomeTag(tag: Tag | string) {
		return typeof tag === 'string'
			? tag
			: tag.nome
	}

	async selecionaItem(item: ItemSelecao | ItemNovo) {
		if (!item) {
			this.$emit('input', null)
			return
		}

		if (item.tipo === 'novo') {
			await this.criarTag()
		} else {
			this.$emit('input', item)
		}
	}

	async criarTag() {
		try {
			if (this.busca.trim() == '') return AlertModule.setError("Não pode ser vazio")
			const tagNova: Tag = { id: '', nome: this.busca }

			const tagCriada = await this.saveTagUseCase.create(tagNova)

			const item: ItemSelecao = { ...tagCriada, tipo: 'selecao' }
			this.itensSelecao.push(item)
			this.emitirInput(item)

			return tagCriada
		} catch (e) {
			AlertModule.setError(e)
		}
	}

	emitirInput(item: ItemSelecao) {
		if (!Array.isArray(this.tag)) {
			this.$emit('input', this.returnObject
				? item
				: item.id,
			)
		} else {
			const tags = this.tag || []
			this.$emit('input', [
				...tags,
				item,
			])
		}
	}

	editar(item) {
		this.itemEditando = { ...item }
	}

	async confirmarEdicao() {
		if (!this.itemEditando) {
			return
		}
		if (!this.itemEditando.nome) {
			this.itemEditando = null
			return
		}
		const indice = this.itensSelecao.findIndex(
			item => item.id === this.itemEditando?.id,
		)
		if (indice === -1) return

		this.itensSelecao.splice(indice, 1, this.itemEditando)
		const tagEditada = {
			id: this.itemEditando.id,
			nome: this.itemEditando.nome,
		}
		await this.saveTagUseCase.update(tagEditada)
		this.itemEditando = null
	}

	filtrar(item: ItemAutocompletar, queryText, itemText) {
		if (item.tipo === 'header') return false

		const hasValue = val => (val ? val : '')
		const textLower = hasValue(itemText).toString().toLowerCase()
		const queryLower = hasValue(queryText).toString().toLowerCase()

		if (item.tipo === 'novo') {
			return (
				queryLower &&
				!this.itensSelecao.some(({ nome }) => nome.toLowerCase() === queryLower)
			)
		}
		if (item.tipo === 'buscar-mais') return this.pagina && !this.pagina.last
		return textLower.includes(queryLower)
	}

	async buscar(paginavel: Pageable = {}): Promise<Tag[]> {
		try {
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findTagUseCase.findAll({
				busca: this.busca || undefined,
				page: paginavel.page || 0,
				size: paginavel.size || 10,
				sort: 'nome',
			}, axiosConfig);
			this.paginacao.page = this.pagina.number
			return this.pagina.content
		} catch (e) {
			if (axios.isCancel(e)) throw e
			AlertModule.setError(e)
			throw e
		}
	}

	async buscarNovosItens() {
		this.buscandoNovosItens = true
		const tags = await this.buscar({
			...this.paginacao,
			page: 0,
		})
		this.itensSelecao = []
		this.inserirTags(tags)
		this.buscandoNovosItens = false
	}

	async buscarMaisItens() {
		this.buscandoMaisItens = true
		const tags = await this.buscar({
			...this.paginacao,
			page: this.paginacao.page + 1,
		})
		this.inserirTags(tags)
		this.buscandoMaisItens = false
	}

	inserirTags(tags: Tag[]) {
		this.itensSelecao = [
			...this.itensSelecao,
			...tags.map<ItemSelecao>(item => ({
				...item,
				tipo: 'selecao',
			})),
		]
	}

	@Watch('busca')
	onChangeBusca = this.buscarNovosItens

	mudouVisibilidade(visivel) {
		if (visivel && !this.buscandoMaisItens) this.buscarMaisItens()
	}
}
export type ItemHeader = {
	tipo: 'header'
	header: string
}

export type ItemSelecao = Tag & {
	tipo: 'selecao'
}

export type ItemNovo = {
	tipo: 'novo'
	text: string
}

export type ItemBuscarMais = {
	tipo: 'buscar-mais'
}

export type ItemAutocompletar = ItemHeader | ItemSelecao | ItemNovo | ItemBuscarMais
