












import { Vue, Component } from 'vue-property-decorator'
import { CEST } from '../../models/fiscal/CEST'
import type { CESTServiceAdapter } from '@/usecases'
import AutocompleteService from '@/components/shareds/AutocompleteService.vue'
import { Inject } from 'inversify-props'

@Component({
	components: {
		AutocompleteService,
	},
})
export default class SeletorDeCest extends Vue {
	@Inject('CESTServiceAdapter')
	private service!: CESTServiceAdapter

	display(cest: CEST) {
		return `${cest.codigo} - ${cest.descricao}`
	}
}
