import {FiltroDeBuscaDeProdutos, FormEdicaoDeProdutos, FormProduto, Tag } from '@/models'
import { container } from 'inversify-props'
import { ProdutoServiceAdapter } from './adapter'

type UseCaseParams = {
	produtoService?: ProdutoServiceAdapter
	campos: CamposAceitos[]
	filtro: string[] | FiltroDeBuscaDeProdutos
	valores: Partial<FormProduto>
	edicaoMassiva: boolean
}

export type CamposAceitos = 'regrasFiscais' | 'tags' | 'cest' | 'categoria' | 'descontoVarejo'

export default async ({
	produtoService = container.get<ProdutoServiceAdapter>('ProdutoServiceAdapter'),
	campos,
	filtro,
	valores,
	edicaoMassiva,
}: UseCaseParams) => {
	if (!campos.length) throw new Error('Selecione ao menos um campo')
	if (Array.isArray(filtro)) {
		if (!filtro.length) throw new Error('Nenhum produto selecionado')
	}

	const camposAAtualizar: Partial<FormProduto> = {}
	if (campos.includes('regrasFiscais')) {
		camposAAtualizar['regrasFiscais'] = valores['regrasFiscais']?.map(
			({
				id,
				regra,
				tipo,
				transacao,
				entradaSaida,
			})=> ({
				id,
				regra: {
					id: regra?.id,
					descricao: regra?.descricao,
				},
				tipo,
				transacao,
				entradaSaida,
			})
		) as any
	}
	
	if (campos.includes('tags')) {
		camposAAtualizar['tags'] = valores['tags']?.map(
			({ id, nome }) => ({
				id,
				nome,
			})
		) as Tag[]
	}
	
	if (campos.includes('categoria')) {
		camposAAtualizar['categoria'] =  valores['categoria']
	}
	
	if (campos.includes('cest')) {
		camposAAtualizar.cest = valores.cest 
	}

	if(campos.includes('descontoVarejo')) {
		camposAAtualizar.descontoVarejo = valores['descontoVarejo']
	}

	if(edicaoMassiva) {
		let editForm: Partial<FormEdicaoDeProdutos> = {}
		editForm = { ...camposAAtualizar }
		editForm.filtro = {
			...filtro,
			tipos: [
				'Padrão',
				'Variante',
				'Composto',
				'Com Variantes',
			],
		}
		return await produtoService.changeProdutosByFiltro({...editForm})
	}

	if (!Array.isArray(filtro)) {
		return await produtoService.changeByFiltro([camposAAtualizar], {
			...filtro,
			tipos: [
				'Padrão',
				'Variante',
				'Composto',
				'Com Variantes',
			],
		})	
	}

	const updates: Partial<FormProduto>[] = filtro.map(id => ({
		...camposAAtualizar,
		id,
	}))
	return await produtoService.changeByFiltro(updates)
}
