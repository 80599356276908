








































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { FormProduto, FiltroDeBuscaDeProdutos } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import EdicaoDeRegrasFiscaisDoProduto from '@/components/produto/EdicaoDeRegrasFiscaisDoProduto.vue'
import EditarMultiplosProdutosUseCase, { CamposAceitos } from '@/usecases/produto/EditarMultiplosProdutosUseCase'
import SeletorDeTag from '@/components/produto/SeletorDeTag.vue'
import SeletorDeCest from '@/components/produto/SeletorDeCest.vue'
import SeletorDeCategoria from '@/views/application/produtos/EdicaoDeProduto/SeletorDeCategoria.vue'

@Component({
	components: {
		EdicaoGenerica,
		EdicaoDeRegrasFiscaisDoProduto,
		SeletorDeTag,
		SeletorDeCest,
		SeletorDeCategoria,
	},
})
export default class EdicaoMultiplaDeProdutos extends Vue {
	@Prop({ type: [Array, Object], default: () => [] }) selecionados!: string[] | FiltroDeBuscaDeProdutos
	@Prop({ type: Boolean, default: false }) usarTodosOsFiltrados!: boolean
	campos: CamposAceitos[] = []
	valores: Partial<FormProduto> = {
		regrasFiscais: [],
		tags: [],
		cest: null,
		categoria: null,
		descontoVarejo: null,
	}
	enviando = false
	erro: null | Error = null

	async salvar() {
		try {
			this.enviando = true
			await EditarMultiplosProdutosUseCase({
				campos: this.campos,
				filtro: this.selecionados,
				valores: this.valores,
				edicaoMassiva: false,
			})
			this.usarTodosOsFiltrados 
				? AlertModule.setSuccess('Atualização de dados será realizada, ao finalizar o processo você será notificado') 
				: AlertModule.setSuccess('Atualização concluída com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.enviando = false
		}
	}
}

