

























import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import type ServiceAdapter from '@/usecases/adapter/ServiceAdapter'
import axios, { CancelTokenSource } from 'axios'

@Component
export default class AutocompleteService extends Vue {
	@PropSync('value', {
		type: [Object, Array],
		default: null,
	}) selected!: null | Record<string, any> | Record<string, any>[]
	@Prop() service!: ServiceAdapter
	@Prop() display!: (item: any) => string
	@Prop() params!: any
	items: any[] = []
	loading = false
	errors: string[] = []
	busca: string | null = null

	cancelToken: CancelTokenSource | null = null

	$refs!: {
		campo: HTMLInputElement
	}

	focus() {
		this.$refs.campo.focus()
	}

	get todosOsItems() {
		if (!this.selected) return this.items
		if (!this.busca)
			return Array.isArray(this.selected)
				? this.selected
				: [this.selected]
		if (Array.isArray(this.selected)) {
			if (!this.selected.length) return this.items
			if (this.selected.some(
				selection => this.busca === this.display(selection),
			)) {
				return this.selected.filter(value => !!value)
			}
		} else {
			if (!this.selected) return this.items
			if (this.selected && this.busca === this.display(this.selected)) {
				return [this.selected].filter(value => !!value)
			}
		}
		if (
			(this.selected && this.busca === this.display(this.selected))
		) {
			return [this.selected].filter(value => !!value)
		}
		return this.items
	}

	@Watch('busca')
	onChangeBusca(busca: AutocompleteService['busca']) {
		this.buscarItensPorNome(busca)
	}

	async buscarItensPorNome(search: string | null) {
		if (this.cancelToken) this.cancelToken.cancel()
		if (search === '') {
			this.items = []
			this.loading = false
			return
		}

		try {
			this.loading = true
			this.cancelToken = axios.CancelToken.source()

			this.items = (
				await this.service.find(
					{ search, ...this.params },
					{
						cancelToken: this.cancelToken.token,
					},
				)
			).content

			this.loading = false
		} catch (error: any) {
			if (axios.isCancel(error)) return
			this.loading = false
		}
	}
}
