













































































import {Prop, Vue, Component, Watch } from "vue-property-decorator"
import AlertModule from "@/store/vuex/aplicacao/AlertModule"
import axios, { CancelTokenSource } from 'axios'
import { DataOptions } from 'vuetify'
import { Categoria } from '@/models'
import { Pageable } from '@/models/Pageable'
import { Page } from '@/models'
import { ObserveVisibility } from "vue-observe-visibility"
import { FindCategoriaUseCase } from "@/usecases/produto/categoria/FindCategoriaUseCase"
import { SaveCategoriaUseCase } from "@/usecases/produto/categoria/SaveCategoriaUseCase"

@Component({
	model: {
		prop: 'categoria',
	},
	directives: {
		ObserveVisibility,
	},
})
export default class SeletorDeCategoria extends Vue {
	@Prop({ type: [Object, Array], default: null }) categoria!: (string | Categoria) | (string | Categoria)[] | null
	@Prop({ type: Boolean }) podeCriar!: boolean
	@Prop({ type: Boolean }) podeEditar!: boolean
	@Prop({ type: Boolean }) returnObject!: boolean 
	@Prop({ type: Boolean }) multiple!: boolean

	cancelToken: CancelTokenSource | null = null
	buscandoNovosItens = true
	findMCategoriaUseCase = new FindCategoriaUseCase()
	saveCategoriaUseCase = new SaveCategoriaUseCase()

	pagina: Page<Categoria> | null = null

	busca = ''
	buscandoMaisItens = false
	itemEditando: null | ItemSelecao = null
	itensSelecao: ItemSelecao[] = []
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	created() {
		this.buscarNovosItens()
	}

	filtrar(item: ItemAutocompletar, queryText, itemText) {
		if (item.tipo === 'header') return false

		const hasValue = val => (val ? val : '')
		const textLower = hasValue(itemText).toString().toLowerCase()
		const queryLower = hasValue(queryText).toString().toLowerCase()

		if (item.tipo === 'novo') {
			return (
				queryLower &&
				!this.itensSelecao.some(({ nome }) => nome.toLowerCase() === queryLower)
			)
		}
		if (item.tipo === 'buscar-mais') return this.pagina && !this.pagina.last
		return textLower.includes(queryLower)
	}
	async buscar(paginavel: Pageable = {}): Promise<Categoria[]> {
		try {
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findMCategoriaUseCase.findAll({
				busca: this.busca || undefined,
				page: paginavel.page || 0,
				size: paginavel.size || 10,
				sort: 'nome',
			}, axiosConfig);
			this.paginacao.page = this.pagina.number
			return this.pagina.content
		} catch (e) {
			if (axios.isCancel(e)) throw e
			AlertModule.setError(e)
			throw e
		}
	}

	categoriaEmItemAutocompletar(categoria: Categoria | string): ItemSelecao {
		return typeof categoria === 'string'
			? {
				id: '',
				nome: categoria,
				tipo: 'selecao',
			}
			: {
				...categoria,
				tipo: 'selecao',
			}
	}
	async selecionaItem(item: ItemSelecao | ItemNovo) {
		if (!item) {
			this.$emit('input', null)
			return
		}

		if (item.tipo === 'novo') {
			await this.criarCategoria()
		} else {
			this.$emit('input', item)
		}
	}

	get itens() {
		let itens: ItemAutocompletar[] = this.categoria === null
			? []
			: (Array.isArray(this.categoria)
				? this.categoria.map(this.categoriaEmItemAutocompletar)
				: [this.categoriaEmItemAutocompletar(this.categoria)])

		itens = [
			...itens,
			...this.podeCriar
				? [{
					tipo: 'header',
					header: 'Selecione uma opção ou crie uma',
				} as ItemHeader]
				: [],
			...this.itensSelecao,
		]
		if (this.pagina && !this.pagina.last && !this.buscandoNovosItens)
			itens.push({ tipo: 'buscar-mais' })

		const categorias = this.categoria
		if (!this.podeCriar || !this.busca || this.buscandoNovosItens) return itens
		if (!categorias) return [{ tipo: 'novo' }, ...itens]
		if (Array.isArray(categorias)) {
			return categorias.some(
				categoria => this.busca.toLowerCase() === this.obterCategoria(categoria).toLowerCase(),
			)
				? itens
				: [{ tipo: 'novo' }, ...itens]
		}
		return this.busca.toLowerCase() === this.obterCategoria(categorias).toLowerCase()
			? itens
			: [{ tipo: 'novo' }, ...itens]
	}
	obterCategoria(categoria: Categoria | string) {
		return typeof categoria === 'string'
			? categoria
			: categoria.nome
	}
	async criarCategoria() {
		try {
			if (this.busca.trim() == '') return AlertModule.setError("Não pode ser vazio")
			const categoriaNova: Categoria = { id: '', nome: this.busca }

			const categoriaCriada = await this.saveCategoriaUseCase.create(categoriaNova)

			const item: ItemSelecao = { ...categoriaCriada, tipo: 'selecao' }
			this.itensSelecao.push(item)
			this.emitirInput(item)

			return categoriaCriada
		} catch (e) {
			AlertModule.setError(e)
		}
	}
	async buscarMaisItens() {
		this.buscandoMaisItens = true
		const categorias = await this.buscar({
			...this.paginacao,
			page: this.paginacao.page + 1,
		})
		this.inserirCategoria(categorias)
		this.buscandoMaisItens = false
	}

	inserirCategoria(categoria: Categoria[]) {
		this.itensSelecao = [
			...this.itensSelecao,
			...categoria.map<ItemSelecao>(item => ({
				...item,
				tipo: 'selecao',
			})),
		]
	}
	
	editar(item) {
		this.itemEditando = { ...item }
	}
	
	emitirInput(item: ItemSelecao) {
		if (!Array.isArray(this.categoria)) {
			this.$emit('input', this.returnObject
				? item
				: item.id,
			)
		} else {
			const categorias = this.categoria || []
			this.$emit('input', [
				...categorias,
				item,
			])
		}
	}

	
	async confirmarEdicao() {
		if (!this.itemEditando) {
			return
		}
		if (!this.itemEditando.nome) {
			this.itemEditando = null 
			return
		}
		const indice = this.itensSelecao.findIndex(
			item => item.id === this.itemEditando?.id,
		)
		if (indice === -1) return

		this.itensSelecao.splice(indice, 1, this.itemEditando)
		const categoriaEditada = {
			id: this.itemEditando.id,
			nome: this.itemEditando.nome,
		}
		await this.saveCategoriaUseCase.update(categoriaEditada)
		this.itemEditando = null
	}

	async buscarNovosItens() {
		this.buscandoNovosItens = true
		const marcas = await this.buscar({
			...this.paginacao,
			page: 0,
		})
		this.itensSelecao = []
		this.inserirCategoria(marcas)
		this.buscandoNovosItens = false
	}
	
	@Watch('busca')
	onChangeBusca = this.buscarNovosItens

	mudouVisibilidade(visivel) {
		if (visivel && !this.buscandoMaisItens) this.buscarMaisItens()
	}
}
export type ItemHeader = {
	tipo: 'header'
	header: string
}

export type ItemSelecao = Categoria & {
	tipo: 'selecao'
}

export type ItemNovo = {
	tipo: 'novo'
	text: string
}

export type ItemBuscarMais = {
	tipo: 'buscar-mais'
}

export type ItemAutocompletar = ItemHeader | ItemSelecao | ItemNovo | ItemBuscarMais
